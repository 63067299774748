import Auth0 from "auth0-js";

let getWebAuth = () => {
  return new Auth0.WebAuth({
    domain: 'intech.auth0.com',
    clientID: 'oQ9M7zLcoG5lsuIz1TF3gXvo57mCqvw6',
    redirectUri: process.env.VUE_APP_CALLBACK_URI,
    audience: 'https://api.energy360.com',
    responseType: 'token id_token',
    scope: 'openid email profile'
  });
}

export default class AuthenticationService {
  constructor() {
    this.auth0 = getWebAuth();
  }

  isAuthenticated() {
    let expiresAt = localStorage.getItem('expires_at');
    if (expiresAt == null) return false;

    return new Date().getTime() < JSON.parse(expiresAt);
  }

  handleAuthentication() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = window.location.origin + '/callback' //hack for alpha-uat / plain alpha
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, result) => {
        if (err) return reject(err);
        resolve(result);
      });
    });
  }

  login() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = window.location.origin + '/callback' //hack for alpha-uat / plain alpha
    this.auth0.authorize();
  }

  logout() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri=window.location.origin + '/callback' //hack for alpha-uat / plain alpha
    this.auth0.logout();
  }

  checkSession() {
    if (!this.auth0) this.auth0 = getWebAuth();
    this.auth0.baseOptions.redirectUri = window.location.origin + '/callback' //hack for alpha-uat / plain alpha
    return new Promise((resolve, reject) => {
      const options = { connection: 'google-oauth2' };
      this.auth0.checkSession(options, (err, result) => {
        if (err) return reject(err);
        resolve(result);
      });
    });
    // this.auth0.authorize({ connection: 'google-oauth2' });
  }
}