<template>
  <v-app id="app" :style="cssProps">
    <v-app-bar fixed app id="app-bar">
      <v-btn icon @click="goBack" v-if="!isKiosk">
        <v-icon>{{ "arrow_back" }}</v-icon>
      </v-btn>
      <v-toolbar-title class="headline text-uppercase pr-4" v-if="isKiosk">
        <v-img src="../public/images/panda_official_logos.png"></v-img>
      </v-toolbar-title>
      <v-toolbar-title class="headline text-uppercase">
        <v-img
          v-if="!mesaTheme"
          src="../public/images/energy360-updated-logo.png"
        ></v-img>
        <v-img v-if="mesaTheme" src="../public/images/mesa_logo.jpg"></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer
      class="main-sidebar"
      stateless
      v-if="!isKiosk"
      :mini-variant="mini"
      v-model="drawer"
      fixed
      app
      id="nav"
      :key="componentKey"
    >
      <v-menu
        v-if="isAuthenticated"
        class="d-block"
        bottom
        offset-y
        nudge-right="10"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-avatar>
                <img :src="jwtPicture" :title="jwtName" alt="avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ jwtName }}</v-list-item-title>
              </v-list-item-content>

              <v-btn icon v-on="on">
                <v-icon>{{ "arrow_drop_down" }}</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </template>

        <v-list id="nav-admin">
          <!-- <list-group
            :items="adminConfigItems"
            v-if="showAdminMenu === true"
          ></list-group> -->

          <v-list-group
            prepend-icon="manage_accounts"
            v-if="showAdminMenu === true"
          >
            <template v-slot:activator>
              <v-list-item-title>Administration</v-list-item-title>
            </template>
            <template v-for="([title, icon, to, visible], i) in setupItems">
              <v-list-item :key="i" :to="to" v-if="visible == true">
                <v-list-item-title
                  v-text="title"
                  class="pl-6"
                ></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> </v-list-item
            ></template>
          </v-list-group>

          <v-list-group prepend-icon="security" v-if="showAdminMenu === true">
            <template v-slot:activator>
              <v-list-item-title>Security</v-list-item-title>
            </template>
            <template v-for="([title, icon, to, visible], i) in securityItems">
              <v-list-item :key="i" :to="to" v-if="visible == true">
                <v-list-item-title
                  v-text="title"
                  class="pl-6"
                ></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> </v-list-item
            ></template>
          </v-list-group>

          <v-list-group
            prepend-icon="settings_input_component"
            v-if="showAdminMenu === true"
          >
            <template v-slot:activator>
              <v-list-item-title>Monitors</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Eguages</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="([title, icon, to, visible], i) in eguageItems">
                <v-list-item :key="i" :to="to" v-if="visible == true">
                  <v-list-item-title v-text="title"></v-list-item-title>

                  <v-list-item-icon>
                    <v-icon v-text="icon"></v-icon>
                  </v-list-item-icon> </v-list-item
              ></template>
            </v-list-group>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Piera</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="([title, icon, to, visible], i) in pieraItems">
                <v-list-item :key="i" :to="to" v-if="visible == true">
                  <v-list-item-title v-text="title"></v-list-item-title>

                  <v-list-item-icon>
                    <v-icon v-text="icon"></v-icon>
                  </v-list-item-icon> </v-list-item
              ></template>
            </v-list-group>
            <template v-for="([title, icon, to, visible], i) in monitorItems">
              <v-list-item :key="i" :to="to" v-if="visible == true">
                <v-list-item-title
                  v-text="title"
                  class="pl-6"
                ></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> </v-list-item
            ></template>
          </v-list-group>

          <v-list-group
            prepend-icon="room_preferences"
            v-if="showAdminMenu === true"
          >
            <template v-slot:activator>
              <v-list-item-title>Controls</v-list-item-title>
            </template>
            <template v-for="([title, icon, to, visible], i) in controlItems">
              <v-list-item v-if="visible == true" :key="i" :to="to">
                <v-list-item-title
                  v-text="title"
                  class="pl-6"
                ></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>

          <v-divider v-if="showAdminMenu === true"></v-divider>
          <v-list-item v-if="hasTermsOfService" @click="openTermsOfService()">
            <v-list-item-title>Terms of Services</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-list class="pt-0" v-if="showFeatures === true">
        <template v-for="(item, index) in featureItems">
          <v-list-item
            v-if="!item.items && item.show === true"
            :index="index"
            :to="item.to"
            :key="item.title"
          >
            <v-list-item-icon>
              <v-icon medium>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :to="item.to">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            class="group-heading"
            :prepend-icon="item.icon"
            no-action
            :key="item.title"
            v-if="item.items && item.show === true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.items"
              :key="subItem.title"
              :to="subItem.to"
              style="margin-left: 1px"
            >
              <v-list-item-icon v-if="subItem.show === true">
                <v-icon v-text="subItem.icon" />
              </v-list-item-icon>

              <v-list-item-content
                v-text="subItem.title"
                v-if="subItem.show === true"
              />
            </v-list-item>
          </v-list-group>
        </template>
        <template>
          <v-list-item>
            <v-list-item-icon
              title="collapse / expand menu"
              @click.stop="mini = !mini"
              v-if="!isKiosk"
              style="cursor: pointer"
            >
              <v-icon>{{ mini ? "chevron_right" : "chevron_left" }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :to="'/contact_us'"
            class="ml-5 mt-5"
            v-on="on"
            style="background-color: white !important; color: black !important"
          >
            <v-icon id="support_icon" left>contact_support</v-icon>Contact Us
          </v-btn>
        </template>

        <span>Issues with {{ appName }}? Click to contact support.</span>
      </v-tooltip>
    </v-navigation-drawer>

    <v-content>
      <v-container style="height: 100%" fluid>
        <router-view />
      </v-container>
    </v-content>
    <TermsOfService
      :show="showTermsOfService"
      @onclose="handleTermsOfServiceClose"
      @onhastermsofservice="handleHasTermsOfService"
    />
  </v-app>
</template>

<style >
#support_icon {
  color: black !important;
}
#app {
  background-color: var(--v-background-base);
}
#nav {
  background-color: var(--navbar-secondary-background);
}
#nav-admin .v-list-item__title {
  color: var(--navbar-admin-secondary-color);
}
#app-bar {
  background-color: var(--secondary-background);
}
#nav .v-list-item__title {
  color: var(--secondary-color);
}
#nav .theme--light.v-navigation-drawer.main-sidebar .v-icon,
#nav .v-list-item__content {
  color: var(--secondary-color) !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import TermsOfService from "./components/TermsOfService";
// import ListGroup from "./components/Menus/MenuGroup";

export default {
  name: "App",
  components: {
    TermsOfService,
  },
  data() {
    return {
      drawer: true,
      mini: false,

      showTermsOfService: false,
      hasTermsOfService: { type: Boolean, default: false },

      showAdminMenu: false,
      showFeatures: false,
      mesaTheme: false,
      appName: process.env.VUE_APP_BRANDING,
      appThemeBackground: "",
      appNavColor: "",
      appNavAdminColor: "",
      componentKey: 0,
      backgroundColor: "",
      isKiosk: null,

      eguageItems: [],
      pieraItems: [],
      monitorItems: [],
      controlItems: [],
      setupItems: [],
      securityItems: [],
      featureItems: [],
      items: [],
    };
  },

  async mounted() {
    await this.initializeSession();

    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.mini = true;
    }
    this.mesaTheme = this.isMesa();
    this.isKiosk = this.$route.params.kiosk === "kiosk";
  },

  watch: {
    isKiosk: function (kiosk) {
      console.log(`is kiosk route: ${kiosk}`);
      if (kiosk === true) {
        this.backgroundColor = "#ecf2f6";
      } else {
        this.backgroundColor = "#ecf2f6"; //"#fafafa"; we have changed app background to match kiosk now
      }
    },
  },

  computed: {
    ...mapGetters("session", [
      "isAuthenticated",
      "jwtName",
      "jwtPicture",
      "userSession",
      "hasNewTermsOfServices",
    ]),
    ...mapGetters("application", { activePotfolio: "getActivePortfolio" }),
    cssProps() {
      return {
        "--secondary-background": this.appThemeBackground,
        "--navbar-secondary-background": this.navbarBackground,
        "--secondary-color": this.appNavColor,
        "--navbar-admin-secondary-color": this.appNavAdminColor,
        "--v-background-base": this.backgroundColor,
      };
    },
  },

  created() {
    const self = this;
    this.$store.subscribe((mutation) => {
      if (mutation.type === "session/userPermissionsSet") {
        (this.showAdminMenu = this.isAdmin()),
          (this.showFeatures = this.hasFeatures()),
          (this.eguageItems = [
            ["My Eguages", "speed", "/monitors/egauge", this.$can("read", "meters")],
            [
              "Virtual Monitors",
              "settings_system_daydream",
              "/monitors/virtual_monitor",
              this.$can("create", "sites"),
            ],
            [
              "End Uses",
              "account_tree",
              "/site/enduses",
              this.$can("create", "sites"),
            ],
            [
              "Sensors",
              "sensors",
              "/site/sensors",
              this.$can("read", "meters"),
            ],
          ]),
          (this.pieraItems = [
            [
              "My Piera Monitors",
              "multiline_chart",
              "/monitors/piera",
              this.$can("read", "meters"),
            ],
            [
              "Piera Login",
              "login",
              "/monitors/piera/login",
              this.$can("create", "sites"),
            ],
          ]),
          (this.monitorItems = [
            [
              "Utility Meters",
              "track_changes",
              "/utility_meters",
              this.$can("create", "sites"),
            ],
            [
              "Equipment",
              "widgets",
              "/site/equipment",
              this.$can("read", "meters"),
            ],
            [
              "Site Location",
              "blur_circular",
              "/site/sitelocation",
              this.$can("create", "sites"),
            ],
          ]),
          (this.controlItems = [
            [
              "Control Permissions",
              "admin_panel_settings",
              "/administration/control_permissions",
              this.$can("read", "meters"),
            ],
          ]),
          (this.setupItems = [
            [
              "Users",
              "account_box",
              "/administration/users",
              this.$can("create", "users"),
            ],
            ["Sites", "home_work", "/sites", this.$can("create", "sites")],
          ]),
          (this.securityItems = [
            [
              "Permissions - Core",
              "lock_open",
              "/administration/permissions/energy360core",
              this.$can("create", "users"),
            ],
            [
              "Permissions - Analytics",
              "lock_open",
              "/administration/permissions/analytics",
              this.$can("create", "users"),
            ],
          ]),
          (this.featureItems = [
            // { icon: "home", title: "Home", to: "/", show: true },
            {
              icon: "folder",
              title: "Portfolio",
              to: "/portfolios",
              show: self.$can("read", "portfolios"),
            },

            {
              icon: "build",
              title: "Controls",
              to: "/controls",
              show: self.$can("read", "controls"),

              items: [
                {
                  icon: "thermostat",
                  title: "Thermostats",
                  to: "/controls/thermostats",
                  show: true,
                },
                {
                  icon: "whatshot",
                  title: "Boilers",
                  to: "/controls/boilers",
                  show: true,
                },
                {
                  icon: "toggle_on",
                  title: "Power Controls",
                  to: "/controls/power-controls",
                  show: true,
                },
              ],
            },
            {
              icon: "wb_incandescent",
              title: "Strategies",
              to: "/strategies",
              show: self.$can("create", "strategies"),
            },
            {
              icon: "wb_incandescent",
              title: "StrategyTemplates",
              to: "/strategyTemplates",
              show: self.$can("create", "strategies"),
            },
            {
              icon: "insert_chart",
              title: "Reports",
              to: "/reports",
              show: self.$can("read", "reports"),
            },
            {
              icon: "check_circle",
              title: "Analytics",
              show: self.$can("read", "analytics"),
              items: [
                {
                  icon: "museum",
                  title: "Models",
                  to: "/analytics/models",
                  show: self.$can("read", "analytics"),
                },
                {
                  icon: "apartment",
                  title: "Projects",
                  to: "/analytics/mandv/projects",
                  show: self.$can("read", "analytics"),
                },
                {
                  icon: "storm",
                  title: "Non-Routine Events",
                  to: "/analytics/nonroutineevents",
                  show: self.$can("read", "analytics"),
                },
                {
                  icon: "assignment_turned_in",
                  title: "Measures/ Opportunities",
                  to: "/analytics/mandv/measures",
                  show: self.$can("read", "measures"),
                },
              ],
            },
          ]);
      }
    });
  },

  methods: {
    ...mapActions("session", ["logout", "initializeSession"]),
    openTermsOfService() {
      this.showTermsOfService = true;
    },
    handleTermsOfServiceClose() {
      this.showTermsOfService = false;
    },
    handleHasTermsOfService(hasTermsOfService) {
      this.hasTermsOfService = hasTermsOfService;
    },
    isMesa() {
      //let hostname = window.location.hostname;
      console.log(process.env.VUE_APP_BRANDING);
      let mesa = process.env.VUE_APP_BRANDING === "MesaEnergy365";
      if (mesa === true) {
        this.appThemeBackground = "#E4E2C9";
        this.navbarBackground = "#E4E2C9";
        this.appNavColor = "rgba(0, 0, 0, 0.65)";
      } else {
        this.appThemeBackground = "#f5f5f5";
        this.navbarBackground = "#2e3c54";
        this.appNavColor = "#ffffff";
        this.appNavAdminColor = "rgba(0, 0, 0, 0.65)";
      }
      return mesa;
    },
    isAdmin() {
      let isAdmin = false;
      if (this.$can("read", "unconfigured")) {
        return isAdmin;
      } else {
        isAdmin = this.$can("create", "users");
      }

      return isAdmin;
    },

    hasFeatures() {
      if (this.$can("read", "unconfigured")) {
        return false;
      }
      return true;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>