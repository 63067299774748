<template>
    <v-progress-linear :indeterminate="true"></v-progress-linear>
</template>

<script>
    import { mapActions } from "vuex";
    import router from "@/router";

    export default {
        name: 'Callback',
        created() {
            this.handleAuthentication().then(() => router.push('/'));
        },

        data () {
            return {};
        },
        
        methods: mapActions("session", ["handleAuthentication"]),
    };
</script>