import Vue from 'vue';

import './plugins/axios';
import vuetify from './plugins/vuetify';
import './plugins/toasts';
import './plugins/vee-validate';
import './plugins/abilities';
import './plugins/vue2filters';
import './plugins/geolocation'
import "./assets/styles/application.scss";
//import "@/assets/styles/weather-icons/css/weather-icons.css"
import "./plugins/weather-icon-mapper"

import App from './App.vue';
import router from './router';
import store from './store';

//import hc from "highcharts/highcharts";
import HighchartsVue from "highcharts-vue";
//import boost from "highcharts/modules/boost"; 


Vue.config.productionTip = false;
//boost(hc);
Vue.use(HighchartsVue);
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')