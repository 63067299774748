import Vue from "vue";
import Toast from "@/components/Toasts/index.vue";

const Plugin = {
  install(Vue) {
    let component = new Vue(Toast);

    let createComponent = function (options) {
      Object.assign(Toast, options);

      component = new Vue(Toast);
      let $el = component.$mount().$el;

      document.getElementById('app').appendChild($el);

      return component;
    };

    Vue.prototype.$toast = {
      show(message, timeout, color, options = {}) {
        // debugger;
        options.propsData = { message: message, timeout: timeout || 6000, color: color };
        component = createComponent(options);
        component.$on('onactivechanged', (isActive, wasActive) => {
          if (wasActive && !isActive) {
            component = null;
          }
        });
      }
    };

    Vue.axios.interceptors.response.use(
      response => { return response; },
      function (error) {
       
        if (error.errors) {
          if (error.errors.length) {
            error.errors.forEach(element => {
              this.prototype.$toast.show(`There was an error processing your request.\r\n${element}`);
            });
          } else {
              let obj = error.errors;
              let keys = Object.keys(obj);
              let message = '';
              keys.forEach(key => {
                message = message.concat(obj[key.toString()] + '\r\n')
              });


            this.prototype.$toast.show(`There was an error processing your request.\r\n${message}`);
          }
        }
        else {
          this.prototype.$toast.show(`There was an error processing your request.\r\n${error.error}`);
        }

        return Promise.reject(error);
      }.bind(Vue)
    )

    Vue.axios2.interceptors.response.use(
      response => { return response; },
      function (error) {
       
        if (error.errors) {
          if (error.errors.length) {
            error.errors.forEach(element => {
              this.prototype.$toast.show(`There was an error processing your request.\r\n${element}`);
            });
          } else {
              let obj = error.errors;
              let keys = Object.keys(obj);
              let message = '';
              keys.forEach(key => {
                message = message.concat(obj[key.toString()] + '\r\n')
              });


            this.prototype.$toast.show(`There was an error processing your request.\r\n${message}`);
          }
        }
        else {
          this.prototype.$toast.show(`There was an error processing your request.\r\n${error.error}`);
        }

        return Promise.reject(error);
      }.bind(Vue)
    )
  }
}

Vue.use(Plugin);

export default Plugin;