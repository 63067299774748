import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#fafafa'//we're locking this in so the powerbi report backgrounds melt into ours
      }
    },
    options: {
      customProperties: true
    }
 
  }
});
