import moment from "moment";
import router from "@/router";
import api from "@/modules/administration/users/_api"
import AuthenticationService from "@/services/AuthenticationService";
import { updateAbilitiesFromAuth0Permissions } from '@/plugins/abilities'

const authenticationService = new AuthenticationService();

/**
 * Gets permissions from JWT access token.
 * @param {string} accessToken an Auth0 JWT token
 * @returns an array of strings, likely in format "action:model" or "model:action".
 */
function getPermissions(accessToken) {
  let b64Url = accessToken.split('.')[1];
  let b64 = b64Url.replace('-', '+').replace('_', '/');
  let result = JSON.parse(atob(b64));
  return "permissions" in result ? result["permissions"] : [];
}

const mutations = {
  authenticated(state, result) {
    state.authenticated = true;
    state.accessToken = result.accessToken;
    state.idToken = result.idToken;
    state.expiresIn = result.expiresIn;
    state.expiresAt = result.expiresIn + Math.round(new Date().getTime() / 1000);

    console.log(`State expires at: ${state.expiresAt}`);
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${state.accessToken}`;
    window.axios2.defaults.headers.common['Authorization'] = `Bearer ${state.accessToken}`;

    localStorage.setItem('access_token', state.accessToken || "");
    localStorage.setItem('id_token', state.idToken);
    localStorage.setItem('expires_in', state.expiresIn);
    localStorage.setItem('expires_at', state.expiresAt);
  },

  logout(state) {
    state.authenticated = false;
    state.accessToken = "";
    state.idToken = "";
    state.userPermissionsSet = false;

    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
  },

  setNewTermsOfServices(state, result) {
    state.newTermsOfServices = result;
    state.hasNewTermsOfServices = result !== null && result !== undefined;

    localStorage.setItem("hasNewTermsOfServices", JSON.stringify(state.hasNewTermsOfServices));
    localStorage.setItem("newTermsOfServices", JSON.stringify(state.newTermsOfServices));
  },

  userPermissionsSet(state) {
    state.userPermissionsSet = true;
  },

  setDefaultSite(state, id) {
    state.defaultSite = id;
    localStorage.setItem('defaultSite', JSON.stringify(state.defaultSite))
  }
};

const actions = {

  setDefaultSite({ commit }, site) {
    console.log(site);
    commit('setDefaultSite', site);
  },

  login() {
    authenticationService.login();
  },

  logout({ commit }) {
    commit('logout');
    authenticationService.logout();
    router.push('/logout');
  },

  async handleAuthentication({ commit, dispatch }) {

    return authenticationService.handleAuthentication()
      .then(result => {
        commit('authenticated', result);
        dispatch('initializeSession');

        //check if authenticated user is an orphan 
        let auth0Id = result.idTokenPayload.sub;
        api.isAuth0UserOrphan(auth0Id).then(orphanStatus => {
          //if orphan create e360 user
          if (orphanStatus === true) {
            console.log(`createUser required for ${auth0Id}`)

            api.createE360User(auth0Id).then(user => {
              console.log(user);

              //set default user role
              api.setDefaultUserRole(auth0Id).then(result => {

                console.log(result);
                //finally check terms of service
                dispatch('checkTermsOfService');

              }).catch(err => {
                console.error(err);
              })

            }).catch(err => {
              console.error(err)
            });

          } else {
            dispatch('checkTermsOfService');
          }
        }).catch(err => {
          console.error(err);
        });

      });
  },

  checkTermsOfService({ commit }) {
    //check for any new terms of services for 
    window.axios.get("v1/termsofservices").then(response => {
      if (response && response.data && response.data.data) {
        commit("setNewTermsOfServices", response.data.data);
      }
    }).catch(err => {
      console.error(err);
    });
  },

  async initializeSession({ dispatch, commit, state }) {
    if (!state.authenticated) return;

    let { expiresAt } = state;

    let expires = moment.unix(expiresAt);
    let now = moment();
    let refreshAt = expires.clone().subtract(30, 'minutes');  //clone expires otherwise value will be mutated when math ops performed

    let difference = moment.duration(expires.diff(now));

    if (difference.asMinutes() < 10) {
      console.log("Token has expired / will expire in the next ten minutes ... just log the user out.");
      dispatch('logout');
    }

    let refreshToken = () => {
      authenticationService.checkSession().then(results => {
        commit('authenticated', results);
        dispatch('initializeSession');
      }).catch(() => dispatch('logout'));
    };

    let refreshDuration = moment.duration(refreshAt.diff(now));
    if (refreshDuration.asMinutes() < 10) {
      refreshToken();
    } else {
      setTimeout(refreshToken, refreshDuration.asMilliseconds());
    }
    // updates the user permissions after ensuring the user has a session
    if (state.accessToken !== "") {
      await updateAbilitiesFromAuth0Permissions(getPermissions(state.accessToken));
      commit('userPermissionsSet');
    }
  },

  acceptTermsOfServices({ commit }, { termsOfServiceId }) {
    window.axios.post(`v1/termsofservices/${termsOfServiceId}`).then(response => {
      if (response && response.data && response.data.data) {
        commit("setNewTermsOfServices", null);
      }
    }).catch(err => {
      console.error(err);
    });
  },
};

const getters = {
  isAuthenticated(state) {
    return state.authenticated;
  },
  jwt: state => state.idToken,
  jwtData: (state, getters) => {
    let base64Url = getters.jwt.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    let result = JSON.parse(atob(base64));
    return result;
  },

  acc: state => state.accessToken,
  accData: (state, getters) => {
    let b64Url = getters.acc.split('.')[1];
    let b64 = b64Url.replace('-', '+').replace('_', '/');
    let result = JSON.parse(atob(b64));
    return result;
  },

  userPermissions: (state, getters) => getters.accData ? getters.accData.permissions : null,
  jwtSubject: (state, getters) => getters.jwtData ? getters.jwtData.sub : null,
  jwtEmail: (state, getters) => getters.jwtData ? getters.jwtData.email : null,
  jwtName: (state, getters) => getters.jwtData ? getters.jwtData.name : null,
  jwtPicture: (state, getters) => getters.jwtData ? getters.jwtData.picture : null,
  hasNewTermsOfServices: state => state.hasNewTermsOfServices,
  newTermsOfServices: state => state.newTermsOfServices,
  defaultSite: state => state.defaultSite
};

const state = {
  authenticated: !!localStorage.getItem('access_token'),
  accessToken: localStorage.getItem('access_token') || "",
  idToken: localStorage.getItem('id_token') || "",
  expiresIn: localStorage.getItem('expires_in'),
  expiresAt: new Date().setTime(localStorage.getItem('expires_at')),
  newTermsOfServices: JSON.parse(localStorage.getItem("newTermsOfServices")),
  hasNewTermsOfServices: localStorage.getItem("hasNewTermsOfServices") === "true",
  defaultSite: JSON.parse(localStorage.getItem("defaultSite")) || null
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};