import Vue from 'vue'
import Router from 'vue-router'

import store from "@/store";
import ability from "@/plugins/abilities"

import Callback from "@/components/Callback.vue";
import Kiosk from "@/components/Kiosk/Kiosk.vue"
// import PreviousMap from 'postcss/lib/previous-map';

Vue.use(Router);

let routes = [];

const KioskRoute = {
  path: "/report/:id/:loadTimer?/:kiosk",
  name: "Kiosk",
  component: Kiosk,
  meta: { allowAnonymous: true }
};

const HomeRoute = {
  path: '/',
  name: 'Home',
  component: () => import('@/modules/portfolios/_components/Index'),
  meta: { permission: 'portfolios:read' }
};

const CallbackRoute = {
  path: "/callback",
  name: "Callback",
  component: Callback,
  meta: { allowAnonymous: true }
};

routes.push(HomeRoute, CallbackRoute, KioskRoute);

const ApplicationErrorRoute = {
  path: "/error",
  name: "ApplicationError",
  component: () => import("@/modules/errors/_components/Index"),
  meta: { allowAnonymous: true }
}
routes.push(ApplicationErrorRoute);

/**********************************
 * Ability testing Routes
 */

const TotallyTerribleRouteForTestingOnly = {
  path: "/badroute",
  name: "BadRoute",
  component: () => import('@/modules/site/_components/Index'),
  beforeEnter(to, from, next) {
    next({
      // path: from.path,
      name: "ApplicationError",
      query: { redirectFrom: to.fullPath, originalUrl: from.fullPath }
    });
  }
};
routes.push(TotallyTerribleRouteForTestingOnly);

/**********************************
 * Portfolios Routes
 */
const PortfoliosIndexRoute = {
  path: "/portfolios",
  name: "PortfoliosRoute",
  component: () => import('@/modules/portfolios/_components/Index'),
  meta: { permission: 'portfolios:read' }
};

const PortfolioSiteDetailsRoute = {
  path: "/portfolios/site/:id",
  name: "PortfolioSiteDetailsRoute",
  component: () => import('@/modules/portfolios/_components/Details'),
  meta: { permission: 'portfolios:read' }
};

routes.push(PortfoliosIndexRoute, PortfolioSiteDetailsRoute);

/**********************************
 * Site Routes
 */
const SiteIndexRoute = {
  path: "/sites",
  name: "SiteIndexRoute",
  component: () => import('@/modules/site/_components/Index'),
  meta: { permission: 'sites:read' }
};

const SiteEditRoute = {
  path: "/site/edit/:id",
  name: "SiteEditRoute",
  component: () => import('@/modules/site/_components/Edit')
};

const SiteCreateRoute = {
  path: "/site/create",
  name: "SiteCreateRoute",
  component: () => import('@/modules/site/_components/Create')
};


routes.push(SiteIndexRoute, SiteCreateRoute, SiteEditRoute);


/***************************************
 * Heat Map Routes
 */
const HeatMapIndexRoute = {
  path: "/heatmaps",
  name: "HeatMapIndex",
  component: () => import('@/modules/heatmaps/_components/Index')
};

routes.push(HeatMapIndexRoute);

/***************************************
* Analytics Routes
*/
const AnalyticsModelIndexRoute = {
  path: "/analytics/models",
  name: "AnalyticsModelIndex",
  component: () => import('@/modules/analytics/analytics_models/_components/Index'),
  meta: { permission: 'analytics:read' }
};

const AnalyticsModelCreateRoute = {
  path: "/site/:siteId/analytics/models/create",
  name: "AnalyticsModelCreate",
  component: () => import('@/modules/analytics/analytics_models/_components/Create')
};

const AnalyticsModelEditRoute = {
  path: "/analytics/models/:id",
  name: "AnalyticsModelEdit",
  component: () => import('@/modules/analytics/analytics_models/_components/Edit')
};

// const AnalyticsModelNREIndexRoute = {
//   path: "/analytics/model/:id/modelnre",
//   name: "AnalyticsModelNREIndex",
//   component: () => import('@/modules/analytics/analytics_models/_components/ModelNREIndex'),
//   meta: { permission: 'analytics:read' }
// };

// const AnalyticsModelNREEnergyAdjustmentChartRoute = {
//   path: "/analytics/model/:id/modelnre/:modelNreId/year/:year/version/:version",
//   name: "AnalyticsModelNreEnergyAdjustmentChart",
//   component: () => import('@/modules/analytics/analytics_models/_components/EnergyAdjustmentChart'),
//   meta: { permission: 'analytics:read' }
// };

const AnalyticsMandVProjectDetailsRoute = {
  path: "/analytics/mandv/projects/:id",
  name: "AnalyticsMandVProjectDetails",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/Edit')
};

const AnalyticsMandVProjectNewRoute = {
  path: "/site/:siteId/analytics/projects/create",
  name: "AnalyticsMandVProjectNew",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/Create')
};

const AnalyticsMandVProjectIndexRoute = {
  path: "/analytics/mandv/projects",
  name: "AnalyticsMandVProjectIndex",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/Index'),
  meta: { permission: 'analytics:read' }
};

const AnalyticsMandVProjectMeasureIndexRoute = {
  path: "/analytics/mandv/projects/:id/projectmeasure",
  name: "AnalyticsMandVProjectMeasureIndex",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/ProjectMeasureIndex'),
  meta: { permission: 'analytics:read' }
};

const AnalyticsMandVProjectMeasureCostSavingsChartRoute = {
  path: "/analytics/mandv/projects/:projectId/projectmeasure/:projectMeasureId/year/:year/version/:version",
  name: "AnalyticsMandVProjectMeasureCostSavingsChart",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/CostSavingsGoalChart'),
  meta: { permission: 'analytics:read' }
};

const AnalyticsMandVProjectImpactReportRoute = {
  path: "/analytics/mandv/project/:id/impact",
  name: "AnalyticsProjectImpactReport",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/ProjectImpactReport')
};

const AnalyticsMandVMeasuresIndexRoute = {
  path: "/analytics/mandv/measures",
  name: "AnalyticsMandVMeasuresIndex",
  component: () => import('@/modules/analytics/m_and_v/measures/_components/Index'),
  meta: { permission: 'measures:read' }
};

const AnalyticsMandVMeasuresDetailsRoute = {
  path: "/analytics/mandv/measures/:measuresId",
  name: "AnalyticsMandVMeasuresDetails",
  component: () => import('@/modules/analytics/m_and_v/measures/_components/Details')
};

const AnalyticsMandVMeasuresNewRoute = {
  path: "/analytics/mandv/measures/new",
  name: "AnalyticsMandVMeasuresNew",
  component: () => import('@/modules/analytics/m_and_v/measures/_components/Details')
};

const AnalyticsMandVProjectEventsDetailsRoute = {
  path: "/analytics/mandv/projects/events/:id",
  name: "AnalyticsMandVProjectEventsDetail",
  component: () => import('@/modules/analytics/m_and_v/projects/_components/DetailsEvent')
};

routes.push(AnalyticsModelIndexRoute,

  AnalyticsModelCreateRoute,
  AnalyticsModelEditRoute,
  // AnalyticsModelNREIndexRoute,
  // AnalyticsModelNREEnergyAdjustmentChartRoute,
  AnalyticsMandVProjectDetailsRoute,
  AnalyticsMandVProjectNewRoute,
  AnalyticsMandVProjectIndexRoute,
  AnalyticsMandVProjectImpactReportRoute,
  AnalyticsMandVMeasuresIndexRoute,
  AnalyticsMandVProjectMeasureIndexRoute,
  AnalyticsMandVProjectMeasureCostSavingsChartRoute,
  AnalyticsMandVMeasuresDetailsRoute,
  AnalyticsMandVMeasuresNewRoute,
  AnalyticsMandVProjectEventsDetailsRoute)

/***************************************
* Site Equipment Routes
*/
const NREIndexRoute = {
  path: "/analytics/nonroutineevents",
  name: "NREIndex",
  component: () => import('@/modules/analytics/m_and_v/nonroutineevents/_components/Index'),
  meta: { permission: 'analytics:create' }
};

const NRECreateRoute = {
  path: "/site/:siteId/analytics/nonroutineevent/create",
  name: "NRECreate",
  component: () => import('@/modules/analytics/m_and_v/nonroutineevents/_components/Create'),
  meta: { permission: 'analytics:create' }
};

const NREEditRoute = {
  path: "/analytics/nonroutineevent/:nonRoutineEventId/edit",
  name: "NREEdit",
  component: () => import('@/modules/analytics/m_and_v/nonroutineevents/_components/Edit'),
  meta: { permission: 'analytics:update' }
};

const NREEnergyAdjustmentChartRoute = {
  path: "/analytics/nonroutineevent/:nonRoutineEventId/:version",
  name: "NREEnergyAdjustmentChart",
  component: () => import('@/modules/analytics/m_and_v/nonroutineevents/_components/EnergyAdjustmentChart'),
  meta: { permission: 'analytics:read' }
};

routes.push(NREIndexRoute, NRECreateRoute, NREEditRoute, NREEnergyAdjustmentChartRoute)

/***************************************
* Load Routes
*/
const LoadsIndexRoute = {
  path: "/loads",
  name: "PortfolioLoadsIndex",
  component: () => import('@/modules/loads/_components/Index')
};

const LoadDetailsRoute = {
  path: "/loads/:id",
  component: () => import('@/modules/loads/_components/Details')
};

routes.push(LoadsIndexRoute, LoadDetailsRoute);

/***************************************
* Control Routes
*/
const ControlsIndexRoute = {
  path: "/controls/:type",
  name: "ControlsIndex",
  component: () => import('@/modules/controls/_components/Index'),
  meta: { permission: 'controls:read' }
};

const ControlsSiteIndexRoute = {
  path: "/controls/:type/site/:siteId",
  name: "ControlsSiteIndex",
  component: () => import('@/modules/controls/_components/Index'),
  meta: { permission: 'controls:read' }
};

const ControlsEditRoute = {
  path: "/controls/:type/:controlId",
  name: "ControlsEdit",
  component: () => import('@/modules/controls/_components/Edit'),
};

routes.push(ControlsIndexRoute, ControlsEditRoute, ControlsSiteIndexRoute);

/***************************************
* Report Routes
*/
const ReportsIndexRoute = {
  path: "/reports",
  name: "PortfolioReportsIndex",
  component: () => import('@/modules/reports/_components/Index'),
  meta: { permission: 'reports:read' }
};

const ReportDetailsRoute = {
  path: "/reports/:id",
  component: () => import('@/modules/reports/_components/Details')
};

const ReportFilterParamsRoute = {
  path: "/portfolio/reports/:id/site/:siteId",  
  name: "PortfolioReportRoute",
  component: () => import('@/modules/portfolios/_components/ReportDetails'),
  props: (route) => ({
    ...route.params
  })
  
};

const ReportKioskRoute = {
  path: "/reports/:id/:loadTimer?/:kiosk",
  component: () => import('@/modules/reports/_components/Details')
};

routes.push(ReportsIndexRoute, ReportDetailsRoute, ReportKioskRoute, ReportFilterParamsRoute);

/***************************************
* Strategies Routes
*/
const StrategiesIndexRoute = {
  path: '/strategies',
  name: 'StrategiesIndex',
  component: () => import('@/modules/strategies/Index'),
  meta: { permission: 'strategies:read' }
};

const StrategiesCreateRoute = {
  path: '/site/:siteId/strategies/create',
  component: () => import('@/modules/strategies/Create')
};

const StrategiesEditRoute = {
  path: '/site/:siteId/strategies/:strategyId',
  component: () => import('@/modules/strategies/Edit')
};

routes.push(StrategiesIndexRoute, StrategiesCreateRoute, StrategiesEditRoute);

/***************************************
* Strategy Templates Routes
*/
const StrategyTemplatesIndexRoute = {
  path: '/strategytemplates',
  name: 'StrategyTemplatesIndex',
  component: () => import('@/modules/strategytemplates/Index'),
  meta: { permission: 'strategies:read' }
};

const StrategyTemplatesCreateRoute = {
  path: '/strategytemplates/create',
  name: 'StrategyTemplatesCreate',
  component: () => import('@/modules/strategytemplates/Create'),
  meta: { permission: 'strategies:create' }
};

const StrategyTemplatesEditRoute = {
  path: '/strategytemplates/:strategyTemplateId',
  name: 'StrategyTemplatesEdit',
  component: () => import('@/modules/strategytemplates/Edit'),
  meta: { permission: 'strategies:create' }
};

routes.push(StrategyTemplatesIndexRoute, StrategyTemplatesCreateRoute, StrategyTemplatesEditRoute);

/***************************************
* Data Upload Routes
*/
const MeterUploadRoute = {
  path: "/site/:siteId/meter/:meterId/data/upload",
  name: "MeterUpload",
  component: () => import('@/modules/data/_components/Upload'),
  meta: { permission: 'sites:read' }
};

routes.push(MeterUploadRoute);

/***************************************
* User Administration Routes
*/
const UserIndexRoute = {
  path: "/administration/users",
  name: "UserIndex",
  component: () => import('@/modules/administration/users/_components/Index'),
  meta: { permission: 'users:read' }
};

const UserEditRoute = {
  path: "/administration/users/edit/:id",
  name: "UserEdit",
  component: () => import('@/modules/administration/users/_components/Edit')
};

const UserCreateRoute = {
  path: "/administration/users/create",
  name: "UserCreate",
  component: () => import('@/modules/administration/users/_components/Create'),
  meta: { permission: 'users:create' }
};

const AvailableEnergy360PermissionsRoute = {
  path: "/administration/permissions/energy360core",
  name: "Permissions",
  component: () => import('@/modules/administration/permissions/_components/Energy360/Index'),
  meta: { permission: 'users:create' }
};

const AvailableAnalyticsPermissionsRoute = {
  path: "/administration/permissions/analytics",
  name: "PermissionsAnalytics",
  component: () => import('@/modules/administration/permissions/_components/Analytics/Index'),
  meta: { permission: 'users:create' }
};

const AvailableFDDPermissionsRoute = {
  path: "/administration/permissions/fdd",
  name: "PermissionsFDD",
  component: () => import('@/modules/administration/permissions/_components/FDD/Index'),
  meta: { permission: 'users:create' }
};

// children: [ //not working for some reason
//     {
//       path: "edit/:id",
//       name: "UserEdit",
//       component: () => import('@/modules/administration/users/_components/Edit')
//     },
//     {
//       path: "create",
//       name: "UserCreate",
//       component: () => import('@/modules/administration/users/_components/Create')
//     }
//   ]
// };

routes.push(UserIndexRoute, UserCreateRoute, UserEditRoute, AvailableEnergy360PermissionsRoute, AvailableAnalyticsPermissionsRoute, AvailableFDDPermissionsRoute);

/***************************************
* Utility Meter Routes
*/
const UtilityMeterIndexRoute = {
  path: "/utility_meters",
  name: "UtilityMeterIndex",
  component: () => import('@/modules/monitors/utility_meters/_components/Index'),
  meta: { permission: 'meters:read' }
};

const UtilityMeterCreateRoute = {
  path: "/utility_meters/create/:id",
  name: "UtilityMeterCreate",
  component: () => import('@/modules/monitors/utility_meters/_components/Create'),
  meta: { permission: 'meters:create' }
};


const UtilityMeterEditRoute = {
  path: "/utility_meters/edit/:id",
  name: "UtilityMeterEdit",
  component: () => import('@/modules/monitors/utility_meters/_components/Edit'),
  meta: { permission: 'meters:update' }
};

//temp
const UtilityMeterEditRoute2 = {
  path: "/utility_meters/edit/site/:siteId/meter/:meterId",
  name: "MeterEdit2",
  component: () => import('@/modules/monitors/utility_meters/_components/Edit'),
  meta: { permission: 'meters:update' }
};


routes.push(UtilityMeterIndexRoute, UtilityMeterCreateRoute, UtilityMeterEditRoute, UtilityMeterEditRoute2)


/***************************************
* SubMetering Routes
*/
const SubMeterEgaugeIndexRoute = {
  path: "/monitors/egauge",
  name: "SubMeterEgaugeIndex",
  component: () => import('@/modules/monitors/egauges/_components/Index'),
  meta: { permission: 'meters:read' }
};

const SubMeterEgaugeCreateRoute = {
  path: "/monitors/egauge/create/:id",
  name: "SubMeterEgaugeCreate",
  component: () => import('@/modules/monitors/egauges/_components/Create'),
  meta: { permission: 'meters:create' }
};

const SubMeterEgaugeEditRoute = {
  path: "/monitors/egauge/site/:siteId/edit/:egaugeId",
  name: "SubMeterEgaugeEdit",
  component: () => import('@/modules/monitors/egauges/_components/Edit'),
  meta: { permission: 'meters:update' }
};

routes.push(SubMeterEgaugeIndexRoute, SubMeterEgaugeCreateRoute, SubMeterEgaugeEditRoute)

/***************************************
* Virtual Monitor Routes
*/
const VirtualMonitorIndexRoute = {
  path: "/monitors/virtual_monitor",
  name: "VirtualMonitorIndex",
  component: () => import('@/modules/monitors/virtual_monitors/_components/Index'),
  meta: { permission: 'meters:read' }
};

const VirtualMonitorCreateRoute = {
  path: "/site/:siteId/virtual_monitor/create",
  name: "VirtualMonitorCreate",
  component: () => import('@/modules/monitors/virtual_monitors/_components/Create'),
  meta: { permission: 'meters:create' }
};


const VirtualMonitorEditRoute = {
  path: "/site/:siteId/virtual_monitors/:virtualMonitorId/edit",
  name: "VirtualMonitorEdit",
  component: () => import('@/modules/monitors/virtual_monitors/_components/Edit'),
  meta: { permission: 'meters:update' }
};

routes.push(VirtualMonitorIndexRoute, VirtualMonitorCreateRoute, VirtualMonitorEditRoute)

/***************************************
* EndUse Routes
*/
const EndUseIndexRoute = {
  path: "/site/enduses",
  name: "EndUseIndex",
  component: () => import('@/modules/site/end_use/_components/Index'),
  meta: { permission: 'sites:create' }
};

const EndUseCreateRoute = {
  path: "/site/:siteId/enduse/create",
  name: "EndUseCreate",
  component: () => import('@/modules/site/end_use/_components/Create'),
  meta: { permission: 'sites:create' }
};

const EndUseEditRoute = {
  path: "/site/:siteId/enduse/:enduseId/monitor/:monitorId/register/:registerId/edit",
  name: "EndUseEdit",
  component: () => import('@/modules/site/end_use/_components/Edit'),
  meta: { permission: 'sites:update' }
};

routes.push(EndUseIndexRoute, EndUseCreateRoute, EndUseEditRoute)

/***************************************
* Site Equipment Routes
*/
const EquipmentIndexRoute = {
  path: "/site/equipment",
  name: "EquipmentIndex",
  component: () => import('@/modules/site/equipment/_components/Index'),
  meta: { permission: 'sites:create' }
};

const EquipmentCreateRoute = {
  path: "/site/:siteId/equipment/create",
  name: "EquipmentCreate",
  component: () => import('@/modules/site/equipment/_components/Create'),
  meta: { permission: 'sites:create' }
};

const EquipmentEditRoute = {
  path: "/site/:siteId/equipment/:equipmentId/edit",
  name: "EquipmentEdit",
  component: () => import('@/modules/site/equipment/_components/Edit'),
  meta: { permission: 'sites:update' }
};

routes.push(EquipmentIndexRoute, EquipmentCreateRoute, EquipmentEditRoute)
/***************************************
* Site Location Routes
*/
const SiteLocationIndexRoute = {
  path: "/site/sitelocation",
  name: "SiteLocationIndex",
  component: () => import('@/modules/site/site_location/_components/Index'),
  meta: { permission: 'meters:read' }
};


const SiteLocationCreateRoute = {
  path: "/site/sitelocation/create/:siteId",
  name: "SiteLocationCreate",
  component: () => import('@/modules/site/site_location/_components/Create'),
  meta: { permission: 'meters:create' }
};

const SiteLocationEditRoute = {
  path: "/site/:siteId/sitelocation/edit/:siteLocationId",
  name: "EgaugeSiteLocationEdit",
  component: () => import('@/modules/site/site_location/_components/Edit'),
  meta: { permission: 'meters:update' }
};

routes.push(SiteLocationIndexRoute, SiteLocationCreateRoute, SiteLocationEditRoute)

/***************************************
* Control Permission Routes
*/
const ControlPermissionsIndexRoute = {
  path: "/administration/control_permissions",
  name: "ControlPermissionsIndex",
  component: () => import('@/modules/administration/control_permissions/_components/Index'),
  meta: { permission: 'meters:read' }
};

const ControlPermissionsCreateRoute = {
  path: "/site/:siteId/control_permissions/create",
  name: "ControlPermissionsCreate",
  component: () => import('@/modules/administration/control_permissions/_components/Create'),
  meta: { permission: 'meters:create' }
};


const ControlPermissionsEditRoute = {
  path: "/site/:siteId/control_permissions/:ControlPermissionId/edit",
  name: "ControlPermissionsEdit",
  component: () => import('@/modules/administration/control_permissions/_components/Index'),
  meta: { permission: 'meters:update' }
};

routes.push(ControlPermissionsIndexRoute, ControlPermissionsCreateRoute, ControlPermissionsEditRoute)

/***************************************
* Sensor Routes
*/
const SensorIndexRoute = {
  path: "/site/sensors",
  name: "SensorIndex",
  component: () => import('@/modules/site/sensor/_components/Index'),
  meta: { permission: 'meters:read' }
};

const SensorCreateRoute = {
  path: "/site/:siteId/sensor/create",
  name: "SensorCreate",
  component: () => import('@/modules/site/sensor/_components/Create'),
  meta: { permission: 'meters:create' }
};


const SensorEditRoute = {
  path: "/site/:siteId/monitor/:monitorId/register/:registerId/sensor/:sensorId/edit",
  name: "SensorEdit",
  component: () => import('@/modules/site/sensor/_components/Edit'),
  meta: { permission: 'meters:update' }
};

routes.push(SensorIndexRoute, SensorCreateRoute, SensorEditRoute)

/***************************************
* Piera Routes
*/
const PieraIndexRoute = {
  path: "/monitors/piera",
  name: "PieraIndex",
  component: () => import('@/modules/monitors/piera/_components/Index'),
  meta: { permission: 'meters:read' }
};

const PieraEditRoute = {
  path: "/monitors/site/:siteId/piera/:deviceId/edit",
  name: "PieraEdit",
  component: () => import('@/modules/monitors/piera/_components/Edit'),
  meta: { permission: 'meters:update' }
};

routes.push(PieraIndexRoute, PieraEditRoute)

/***************************************
* Contact Routes
*/
const ContactFormRoute = {
  path: '/contact_us',
  component: () => import('@/modules/contact/issues'),
};


routes.push(ContactFormRoute);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  const allowAnonymous = to.matched.some(route => route.meta.allowAnonymous);
  const isAuthenticated = store.getters['session/isAuthenticated'];
  const userPermissionsSet = store.getters["session/userPermissionsSet"];

  //router gets hit before initialize session gets to initialize the permissions, so we force it
  if (isAuthenticated && !userPermissionsSet) {
    console.log("user permissions not loaded yet in router");
    store.dispatch("session/initializeSession")
  }

  if (allowAnonymous || isAuthenticated) {
    console.log(`route.beforeEach ${from.path} -> ${to.path}`);
    if ("permission" in to.meta) {
      let [subject, action] = to.meta.permission.split(':');
      let yesWeCant = ability.cannot(action, subject);

      console.log(`route ${to.name} requires can(${action}, ${subject}) and you ${yesWeCant ? '... nope' : 'have it'}`);
      if (yesWeCant) {
        // Back in the U.S.S.R.
        next({
          name: "ApplicationError",
          query: { redirectFrom: to.fullPath, originalUrl: from.fullPath }
        });
      } else {
        // Go go Go Johnny go!
        next();
      }
    } else {
      // no permission defined. Johnny B Goode!
      next();
    }
  } else {
    store.dispatch('session/login');
  }
});

export default router;