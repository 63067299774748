const getUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/users/auth0/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getAllUsers = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/users/all`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getOrphanAuth0Users = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/users/auth0/orphan`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const isAuth0UserOrphan = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/users/auth0/orphan/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const createUser = async (formData) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/users/auth0', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const inviteUser = async (formData) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/users/invite', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const reInviteUser = async (auth0Id) => {
  let result = null;
  let user = {auth0Id: auth0Id};
  try {
    result = await window.axios.post('/v1/users/reinvite', user);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const createE360User = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.post(`/v1/users/create/e360/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const updateUser = async (formData) => {
  let result = null;
  try {
    result = await window.axios.put('/v1/users/update', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const deleteUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.delete(`/v1/users/delete/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const deleteAuth0User = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.delete(`/v1/users/auth0/delete/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getSites = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/sites`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getPortfolio = async () => {
  try {
    let result = await window.axios.get('/v1/sites/portfolio');
    return result.data.data;
    //return result != undefined ? result.data.data : []; 
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const getPortfolioForUser = async (userId) => {
  try {
    let result = await window.axios.get(`/v1/sites/portfolio/${userId}`);
    return result.data.data;
    //return result != undefined ? result.data.data : []; 
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const getReports = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/reports/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getPowerBiReports = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/reports/powerbi/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}
const getReportsForUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/reports/user/${auth0Id}`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getPortfolioReports = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/reports`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getRoles = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/auth0/roles/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getRolesForUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/auth0/roles/user/${auth0Id}`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getAllPermissions = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/auth0/permissions/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getUndefinedPermissions = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/permissions/not_defined`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getUnusedPermissions = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/permissions/not_used`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getControllerPermissions = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/permissions/controller`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getControllerNoPermissions = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/permissions/controller/none`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getUndefinedAnalyticsPermissions = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/permissions/not_defined`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getUnusedAnalyticsPermissions = async () => {
  let result = null;
  try {
    result = await window.axios2.get(`/v1/analytics/userroles/permissions/not_used`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getAnalyticsControllerPermissions = async () => {
  let result = null;
  try {
    result = await window.axios2.get(`/v1/analytics/userroles/permissions/controller`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const getAnalyticsControllerNoPermissions = async () => {
  let result = null;
  try {
    result = await window.axios2.get(`/v1/analytics/userroles/permissions/controller/none`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}


const updateUserPermissions = async (auth0Id, permissions) => {
  let result = null;
  try {
    result = await window.axios.post(`/v1/userroles/auth0/permissions/user/update/${auth0Id}`, permissions);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const updateUserRoles = async (auth0Id, roles) => {
  let result = null;
  try {
    result = await window.axios.post(`/v1/userroles/auth0/roles/user/update/${auth0Id}`, roles);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const setDefaultUserRole = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.post(`/v1/userroles/auth0/roles/user/default/${auth0Id}`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

const sendIssueEmail = async (formData) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/contact/issue', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

export default {
  getAllUsers,
  getOrphanAuth0Users,
  deleteUser,
  deleteAuth0User,
  createUser,
  inviteUser,
  reInviteUser,
  getUser,
  updateUser,
  getSites,
  getPortfolio,
  getPortfolioForUser,
  getReports,
  getPowerBiReports,
  getPortfolioReports,
  getReportsForUser,
  //getDefaultReportForUser,
  getRoles,
  getRolesForUser,
  getAllPermissions,
  updateUserPermissions,
  updateUserRoles,
  getControllerPermissions,
  getControllerNoPermissions,
  getUndefinedPermissions,
  getUnusedPermissions,
  getAnalyticsControllerPermissions,
  getAnalyticsControllerNoPermissions,
  getUndefinedAnalyticsPermissions,
  getUnusedAnalyticsPermissions,
  sendIssueEmail,
  isAuth0UserOrphan,
  createE360User,
  setDefaultUserRole
}