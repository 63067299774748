"use strict";

import Vue from 'vue';
import axios from "axios";
import Qs from "qs";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
const access_token = localStorage.getItem('access_token');
axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

let config = {
  baseURL: process.env.baseURL || process.env.VUE_APP_API_URL || "https://localhost:5001"
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

let config2 = {
  baseURL: process.env.baseANALYTICSURL || process.env.VUE_APP_ANALYTICS_API_URL || "https://localhost:6001"
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

let config3 = {
  baseURL: process.env.baseSensorURL || process.env.VUE_APP_SENSOR_API_URL || "https://localhost:7001"
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};


const _axios = axios.create(config);
const _axios2 = axios.create(config2);
const _axios3 = axios.create(config3);

_axios.defaults.paramsSerializer = params => {
  return Qs.stringify(params, { arrayFormat: 'repeat' });
};

_axios2.defaults.paramsSerializer = params => {
  return Qs.stringify(params, { arrayFormat: 'repeat' });
};

_axios3.defaults.paramsSerializer = params => {
  return Qs.stringify(params, { arrayFormat: 'repeat' });
};

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);

_axios2.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios2.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);

_axios3.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios3.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);


Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Vue.axios2 = _axios2;
  window.axios2 = _axios2;
  Vue.axios3 = _axios3;
  window.axios3 = _axios3;



  Object.defineProperties(Vue.prototype, {
    $http: { get() { return _axios; } },
    axios: { get() { return _axios; } },
    $axios: { get() { return _axios; } },
    $http2: { get() { return _axios2; } },
    axios2: { get() { return _axios2; } },
    $axios2: { get() { return _axios2; } }, 
    $http3: { get() { return _axios3; } },
    axios3: { get() { return _axios3; } },
    $axios3: { get() { return _axios3; } },
  });
};

Vue.use(Plugin);

export default Plugin;
