
export function weatherIcon(iconName) {
    return mappedIconNames[iconName];
}


let mappedIconNames = {
    'clear-day': 'wi-day-sunny',
    'clear-night': 'wi-night-clear',
    'partly-cloudy-day': 'wi-day-cloudy',
    'partly-cloudy-night': 'wi-night-partly-cloudy',
    'rain': 'wi-rain',
    'sleet': 'wi-sleet',
    'snow': 'wi-snow',
    'wind': 'wi-windy',
    'fog': 'wi-fog',
    'cloudy': 'wi-cloudy'
}

//https://erikflowers.github.io/weather-icons/
