import Vue from "vue";
import Vuex from "vuex";

import ApplicationModule from "./modules/application";
import SessionModule from "./modules/session";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        application: ApplicationModule,
        session: SessionModule
    }
});