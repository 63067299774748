<template>
  <section style="height: 100%">
    <div
      style="height: 100%; width: 100%"
      class="mt-4"
      id="reportContainer"
      ref="reportContainer"
    ></div>
  </section>
</template>

<style>
#reportContainer {
  width: 100%;
  height: 100%;
}

#reportContainer iframe {
  border: none;
}
</style>

<script>
import * as powerbi from "powerbi-client";
import store from "./_store";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import moment from "moment";
import { mixin as VueTimers } from "vue-timers";

const STORE_KEY = "$_kiosk_pbi_embed";

export default {
  name: "PowerBIReport",
  mixins: [VueTimers],

  timers: {
    embedConfigRefresh: { time: 300000, autostart: true, repeat: true },
    embedDataRefresh: { time: 600000, autostart: true, repeat: true },
  },

  data() {
    return {
      reportContainer: null,
      embedConfiguration: null,
      pbiEmbed: null
    };
  },

  props: {
    reportId: { type: String, required: true },
    hasSubmit: { type: Boolean, default: false },
    maxHeight: { type: Boolean, default: true },
    height: { type: Number, default: -1 },
  },

  computed: {
    ...mapGetters({
      pbiEmbedList: STORE_KEY + "/pbiEmbedList",
    }),
  },
  watch: {
    reportId: function (newVal) {
      if (!this.hasSubmit && newVal && newVal !== "") {
        this.getReport();
      }
    },
  },

  beforeCreate() {
    let state = this.$store._modules.root._children[STORE_KEY];
    if (!state) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },

  async mounted() {
    this.reportContainer = this.$refs.reportContainer;
    console.log(this.pbiEmbedList);
    if (this.pbiEmbedList !== null && Array.isArray(this.pbiEmbedList)) {
      //use cached embed if it's less than 10 minutes old

      this.pbiEmbed = this.getCachedEmbed();

      if (this.pbiEmbed && this.pbiEmbed) {
        const now = moment().unix();
        if (now - this.pbiEmbed.timestamp <= 600) {
          this.getEmbedConfigFromCache(true);
        } else {
          await this.getEmbedConfigFromAPI(true);
        } 
      } else {
        await this.getEmbedConfigFromAPI(true);
      }
    } else {
      await this.getEmbedConfigFromAPI(true);
    }
  },

  methods: {
    ...mapActions({
      setpbiEmbed: STORE_KEY + "/setpbiEmbed",
    }),

    getCachedEmbed() {
      let reportId = this.reportId;
      let pbiCache = this.pbiEmbedList.find(function (item) {
        return item.reportId === reportId;
      });
      return pbiCache;
    },

    async getEmbedConfigFromAPI(runEmbed) {
      console.log("pbi config from api");
      const url = `/v1/viewsupport/kiosk/embedconfiguration/${this.reportId}/kiosk`;
      
      this.axios.get(url).then((response) => {
        let newConfig = response.data;
        newConfig.timestamp = moment().unix();
        this.setpbiEmbed(newConfig);
        console.log(response.data.data)
        let { token, report } = response.data.data;
        console.log(report)

        this.embedConfiguration = {
          type: "report",
          tokenType: 1,
          accessToken: token.token,
          embedUrl: report.embedUrl,
          id: report.id,
          settings: {
            filterPaneEnabled: false,
          },
        };

        if (runEmbed) {
          this.embedReport(this.embedConfiguration);
        }
      });
    },

    getEmbedConfigFromCache(runEmbed) {
      console.log("pbi config from cache");
      this.pbiEmbed = this.getCachedEmbed();
      this.embedConfiguration = {
        type: "report",
        tokenType: 1,
        accessToken: this.pbiEmbed.token,
        embedUrl: this.pbiEmbed.embedUrl,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
        },
      };

      if (runEmbed) {
        this.embedReport(this.embedConfiguration);
      }
    },

    embedReport(embedConfiguration) {
      const container = this.$refs.reportContainer;

      const pbi = new powerbi.service.Service(
        powerbi.factories.hpmFactory,
        powerbi.factories.wpmpFactory,
        powerbi.factories.routerFactory
      );

      pbi.embed(container, embedConfiguration);
    },

    refreshReport(embedConfiguration) {
      const container = this.$refs.reportContainer;

      const pbi = new powerbi.service.Service(
        powerbi.factories.hpmFactory,
        powerbi.factories.wpmpFactory,
        powerbi.factories.routerFactory
      );
      const report = new powerbi.Report(pbi, container, embedConfiguration);
      console.log(report);

      report.refresh();
    },

    async embedConfigRefresh() {
      console.log("embedConfigRefreshed");
      await this.getEmbedConfigFromAPI(false);
    },

    async embedDataRefresh() {
      console.log("embedDataRefreshed");

      if (this.pbiEmbed !== null) {
        //use cached embed if it's less than 10 minutes old
        const now = moment().unix();
        if (now - this.pbiEmbed.timestamp <= 3600) {
          this.getEmbedConfigFromCache(false);
        } else {
          await this.getEmbedConfigFromAPI(false);
        }
        this.refreshReport(this.embedConfiguration);
      }
    },
  },
};
</script>