import { AbilityBuilder, Rule } from '@casl/ability'
import { abilitiesPlugin, Can } from '@casl/vue'
import Vue from 'vue'

let ability = AbilityBuilder.define(can => {
  // can('read', 'all') // 'all' has specific meaning; don't use it
  can('eat', 'Muffin')
})
Vue.use(abilitiesPlugin, ability);
Vue.component('Can', Can)

export default ability;

const ACTIONS = ['READ', 'CREATE', 'UPDATE', 'DELETE'];

/**
 * Maps an Auth0 permission into an ability @see{@link Rule}.
 * @param {String} permission a string in format "action:model" or "model:action"
 * @returns a Rule object, with action and subject semantically set in either case.
 */
export function mapAuth0PermissionToRule(permission) {
  let [action, subject] = permission.split(':');
  // the rule from auth0 are both read:Sites and Sites:read
  if (ACTIONS.some(x => x === action.toUpperCase())) {
    // it's read:sites
    return new Rule({ action: action, subject : subject})
  } else {
    // it's sites:read
    return new Rule({ action: subject, subject: action});
  }
}

/**
 * Maps an array of Auth0 permissions into ability Rules.
 * @param {[String]} permissions array of Auth0 permissions  @see{@link mapAuth0PermissionToRule}
 *   for further parameters format info.
 */
export function mapAuth0PermissionsToAbilityRules(permissions) {
  return permissions.map(perm => mapAuth0PermissionToRule(perm));
}

/**
 * Updates the abilities with a list Auth0 permissions.
 * @param {[string]} permissions array of Auth0 permissions in either
 * "action:model" or "model:action" format
 * (where action is one of create, read, update, delete).
 */
export async function updateAbilitiesFromAuth0Permissions(permissions) {
  let updatedPermissions = mapAuth0PermissionsToAbilityRules(permissions);
  // console.log('updated permissions', updatedPermissions);
  ability.update(updatedPermissions);
}